import { useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import firebase from "../../firebase"

export const AuthProvider: React.FC = ({children}) => {

  const [userFireBase, setUserFireBase] = useState<firebase.User | null>(null);
  const [user, setUser] = useState<firebase.firestore.DocumentData>([]);

  let refUsers = firebase.firestore().collection("users")
  
  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser) => {
      setUserFireBase(firebaseUser)
    });  
  }, []);
  
  async function loadUser(user){
    let q : Array<firebase.firestore.DocumentData> = []
    await refUsers.where("email", "==", user.email).get()
    .then((doc) => { doc.forEach((d) =>{ q.push({data: d.data()})}) 
    })
    if (q.length === 0 && user.email) {
      addUser(user)
      setUser({
        roadmapsContributed: {},
        subscriptions: [],
        lastVisited: [],
        email: user.email,
        skillsCompleted: {},
      })
    }
    else if (user.email) {
      setUser(q[0].data)
    }
  }

  useEffect(() =>{
    if (userFireBase) {
      loadUser(userFireBase)
    }
  }, [userFireBase])

  function addUser(user) {
    refUsers.doc(user.email).set({
    roadmapsContributed: {},
    subscriptions: [],
    lastVisited: [],
    email: user.email,
    skillsCompleted: {},
    });
  }

  return <AuthContext.Provider value={{userFireBase: userFireBase, user: user}}>{children}</AuthContext.Provider>;
};