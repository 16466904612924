import {useContext} from 'react'

import { AuthContext } from "../../authentication/AuthContext"
import DeleteIcon from '@mui/icons-material/Delete';

import "./ExternalLink.css"

function ExternalLink({link, idxLink, idxElement, deleteLink, element}) {

    const user = useContext(AuthContext);
    let superUser = user && user.userFireBase && user.userFireBase.email === "polluxroadmaps@gmail.com"

    function handleClickDeleteLink(idxLink, idxElement){
        deleteLink(idxLink, idxElement)
    }

    if (link.type === "website"){
        return (
            <div className = "ExternalLink__Container">
                <div className = "ExternalLink">
                    <div>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.50001 16C3.30001 16 2.20001 15.5 1.30001 14.7C-0.499988 12.9 -0.499988 10.1 1.30001 8.30001L2.00001 7.60001L3.40001 9.00001L2.70001 9.70001C1.70001 10.7 1.70001 12.3 2.70001 13.3C3.70001 14.3 5.30001 14.3 6.30001 13.3L9.30001 10.3C10.3 9.30001 10.3 7.70001 9.30001 6.70001L8.60001 6.00001L10 4.60001L10.7 5.30001C12.5 7.10001 12.5 9.90001 10.7 11.7L7.70001 14.7C6.90001 15.5 5.70001 16 4.50001 16Z" fill="#D5494C"/>
                            <path d="M6.00001 11.4L5.30001 10.7C3.50001 8.9 3.50001 6.1 5.30001 4.3L8.30001 1.3C9.20001 0.4 10.3 0 11.5 0C12.7 0 13.8 0.5 14.7 1.3C16.5 3.1 16.5 5.9 14.7 7.7L14 8.4L12.6 7L13.3 6.3C14.3 5.3 14.3 3.7 13.3 2.7C12.3 1.7 10.7 1.7 9.70001 2.7L6.70001 5.7C5.70001 6.7 5.70001 8.3 6.70001 9.3L7.40001 10L6.00001 11.4Z" fill="#D5494C"/>
                        </svg>
                    </div>
                    <a target = "_blank" href = {link.link} className = "Link" rel="noreferrer">
                        <div>
                            &nbsp; {link.name} 
                        </div>
                    </a>
                    {superUser ?
                        <div>
                            <DeleteIcon onClick = {() => handleClickDeleteLink(idxLink, idxElement)}/>
                        </div> :
                        ""
                    }
                </div>
            </div>
        )     
    }
    else if (link.type === "internal") {
        return (
            <div className = "ExternalLink__Container">
                <div className = "ExternalLink">
                    <div>
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.37312 12.2964V4.48395C3.37312 2.5852 4.91187 1.04645 6.81062 1.04645C8.70937 1.04645 10.2481 2.5852 10.2481 4.48395V16.3589C10.2481 18.2577 11.7869 19.7964 13.6856 19.7964C15.5844 19.7964 17.1231 18.2577 17.1231 16.3589V8.54645" stroke="#223554" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
                            <path d="M17.1231 6.04645C18.5038 6.04645 19.6231 4.92716 19.6231 3.54645C19.6231 2.16574 18.5038 1.04645 17.1231 1.04645C15.7424 1.04645 14.6231 2.16574 14.6231 3.54645C14.6231 4.92716 15.7424 6.04645 17.1231 6.04645Z" stroke="#223554" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
                            <path d="M3.37312 19.7964C4.75384 19.7964 5.87312 18.6772 5.87312 17.2964C5.87312 15.9157 4.75384 14.7964 3.37312 14.7964C1.99241 14.7964 0.873123 15.9157 0.873123 17.2964C0.873123 18.6772 1.99241 19.7964 3.37312 19.7964Z" stroke="#223554" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
                        </svg>
                    </div>
                    <a target = "_blank" href = {link.link} className = "Link" rel="noreferrer">
                        <div>
                            &nbsp; {link.name} 
                        </div>
                    </a>
                    {superUser ?
                        <div>
                            <DeleteIcon onClick = {() => handleClickDeleteLink(idxLink, idxElement)}/>
                        </div> :
                        ""
                    }
                </div>
            </div>
        )     
    }else if (link.type === "youtube") {
        return (
            <div className = "ExternalLink__Container">
                <div className = "ExternalLink">
                    <div>
                        <svg viewBox="248.915 209.186 18.647 12.816" width="18.647" height="12.816">
                            <path d="M 267.172 211.187 C 266.958 210.4 266.326 209.779 265.524 209.568 C 264.07 209.186 258.238 209.186 258.238 209.186 C 258.238 209.186 252.408 209.186 250.953 209.568 C 250.151 209.779 249.519 210.4 249.305 211.187 C 248.915 212.615 248.915 215.594 248.915 215.594 C 248.915 215.594 248.915 218.573 249.305 220.001 C 249.519 220.788 250.151 221.409 250.953 221.62 C 252.407 222.002 258.238 222.002 258.238 222.002 C 258.238 222.002 264.07 222.002 265.524 221.62 C 266.326 221.409 266.958 220.788 267.172 220.001 C 267.562 218.573 267.562 215.594 267.562 215.594 C 267.562 215.594 267.562 212.615 267.172 211.187" fill="red"></path>
                            <path d="M 255.422 219.103 L 261.727 215.623 L 255.422 212.142 Z" fill="#fff"></path>
                        </svg>
                    </div>
                    <a target = "_blank" href = {link.link} className = "Link" rel="noreferrer">
                        <div>
                            &nbsp; {link.name} 
                        </div>
                    </a>
                    {superUser ?
                        <div>
                            <DeleteIcon onClick = {() => handleClickDeleteLink(idxLink, idxElement)}/>
                        </div> :
                        ""
                    }
                </div>
            </div>
        )     
    }
    else {
        return(
            <div>
                website or internal
                {superUser ?
                <div>
                    <DeleteIcon onClick = {() => handleClickDeleteLink(idxLink, idxElement)}/>
                </div> :
                ""}
                
            </div>
        )
    }
}

export default ExternalLink
