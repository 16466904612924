import AuthHandler from '../authentication/AuthHandler';

import "./TopBar.css"

export default function TopBar() {

    return (
        <div className = "TopBar__Container">
            <div className = "TopBar__Options__Auth">
                <AuthHandler/>
            </div>
        </div>
    )
}
