import VisibilityIcon from '@mui/icons-material/Visibility';

import "./Card.css"

export default function CardElement({element}) {
  
  return (
    <div className = "card__Container">
      <div className = "title__Container">
        <div className = "cardTitle">{element.title}</div>
        <div className = "cardSubTitle">{"Last updated: " + element.lastUpdated.toDate().toDateString().slice(4,15)}</div>
      </div>
      <div className = "description__Container">
        {element.description}
      </div>
      <div className = "icon__Container">
        <div className = "numberOfViews__InCard">
          <VisibilityIcon />
          &nbsp;&nbsp;
          {element.views}
        </div>
      </div>
    </div>
  );
}
