import React from 'react'

function Start() {
    return (
        <div>
            <svg width="46" height="47" viewBox="0 0 46 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-1.24495e-05 23.5C-1.22506e-05 18.951 1.34891 14.5042 3.87619 10.7219C6.40346 6.93956 9.99557 3.99159 14.1983 2.25077C18.401 0.509957 23.0255 0.0544802 27.4871 0.94194C31.9486 1.8294 36.0468 4.01994 39.2634 7.23655C42.4801 10.4532 44.6706 14.5514 45.5581 19.0129C46.4455 23.4745 45.99 28.099 44.2492 32.3017C42.5084 36.5044 39.5604 40.0965 35.7781 42.6238C31.9958 45.1511 27.549 46.5 23 46.5C16.9021 46.4931 11.056 44.0677 6.74413 39.7559C2.43229 35.444 0.006869 29.5979 -1.24495e-05 23.5Z" fill="#B2D9DB"/>
                <path d="M14 33.5C13.9999 33.3726 14.024 33.2464 14.071 33.128L17.923 23.5L14.071 13.872C13.9952 13.6836 13.9786 13.4767 14.0234 13.2786C14.0681 13.0806 14.1721 12.9008 14.3214 12.7633C14.4708 12.6258 14.6585 12.537 14.8595 12.5087C15.0606 12.4804 15.2655 12.514 15.447 12.605L35.447 22.605C35.602 22.6974 35.7303 22.8285 35.8194 22.9853C35.9085 23.1422 35.9554 23.3196 35.9554 23.5C35.9554 23.6804 35.9085 23.8578 35.8194 24.0147C35.7303 24.1715 35.602 24.3026 35.447 24.395L15.447 34.395C15.2945 34.4712 15.1251 34.5072 14.9547 34.4994C14.7844 34.4917 14.6189 34.4406 14.474 34.3509C14.329 34.2613 14.2093 34.136 14.1264 33.9871C14.0434 33.8381 13.9999 33.6705 14 33.5Z" fill="#517B9A"/>
            </svg>
        </div>
    )
}

export default Start
