import {useState, useContext} from 'react'
import TextField from '@mui/material/TextField';
import { Link } from "react-router-dom";
import firebase from "../../../firebase"
import Popup from 'reactjs-popup';

import { AuthContext } from "../../authentication/AuthContext"

import "../NewRoadmaps/NewRoadmap.css"

const NewRoadmap = () => {
    let refRoadmapSuggestions = firebase.firestore().collection("roadmapSuggestions")
    // let refUsers = firebase.firestore().collection("users")

    const increment = firebase.firestore.FieldValue.increment(1);

    const user = useContext(AuthContext);

    const [newTitle, setNewTitle] = useState("")
    const [description, setDescription] = useState("")

    const [category, setCategory] = useState("Software")
    const [suggestedBy, setSuggestedBy] = useState(user.userFireBase? user.userFireBase.email : "")

    const handleChangeSuggestedBy = e =>{
        setSuggestedBy(e.target.value)
    }

    const handleChangeNewTitle = e =>{
        setNewTitle(e.target.value)
    }

    const handleChangeDescription = e =>{
        setDescription(e.target.value)
    }

    function handleClickCategory(idx){
        setCategory(categories[idx])
    }

    async function suggestRoadmap(){
        let newRoot = newTitle.replace(' ', '_').toLowerCase()

        refRoadmapSuggestions.doc(newRoot).get().then((doc) => {
            if (doc.exists) {
                refRoadmapSuggestions.doc(newRoot).update({timesSuggested: increment})
            } else {
                refRoadmapSuggestions.doc(newRoot).set({
                    title: newTitle,
                    root: newRoot,
                    views: 1,
                    likes: 1,
                    description: description,
                    category: category,
                    lastUpdated: new Date(),
                    suggestedBy: suggestedBy,
                    timesSuggested: 1
                }); 
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });

        // let q : Array<firebase.firestore.DocumentData> = []
        // await refUsers.where("email", "==", user.userFireBase.email).get()
        // .then((doc) => {doc.forEach((d) =>{ q.push({id: d.id, data: d.data()})})})
    }
    
    const categories = ["General", "Health", "Finance", "Psychology", "Software"]
    
    return (
        <div>
            <div className = "SuggestRoadmapInfo__Container">
                <h1>Suggest a new roadmap</h1>
                <p> Thank you for sharing your thoughts and telling us which skills you would like to learn.</p>
                <div className = "TitleInput__Container">
                    <TextField
                        value = {newTitle}
                        onChange = {handleChangeNewTitle}
                        label="Roadmap Title"
                        defaultValue=""
                        helperText="*Required"
                    />
                </div>
                <div className = "DescriptionInput__Container">
                    <TextField 
                        value = {description}
                        onChange = {handleChangeDescription}
                        label="Description"
                        defaultValue=""
                        helperText="*Optional but helps out"
                        multiline
                        rows={4}
                    />
                </div>
                {!user.userFireBase?
                    <div className = "DescriptionInput__Container">
                        <TextField 
                            value = {suggestedBy}
                            onChange = {handleChangeSuggestedBy}
                            label="Email Adress"
                            defaultValue=""
                            helperText="*Not required"
                        />
                    </div>
                :""}
                    {newTitle !== ""? 
                        <Link onClick = {() => suggestRoadmap()} 
                            to={{pathname: "/search"}}>
                            <button className = "ConfirmSuggestion__Button">This would help me out!</button>
                        </Link>
                        : 
                        <Popup trigger={<button className = "ConfirmSuggestion__Button">This would help me out!</button>} position="right center">
                            <p>Please add a Roadmap Title.</p>
                        </Popup>
                    }
            </div>
        </div>
    )
}

export default NewRoadmap