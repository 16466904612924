import {useState} from 'react'
import {useContext} from "react"
import { useLocation, Link } from 'react-router-dom'

import TextField from '@mui/material/TextField';

import firebase from "../../../firebase"
import { AuthContext } from "../../authentication/AuthContext"

export default function SuggestSkill() {

    const user = useContext(AuthContext);

    const location = useLocation()
    
    const [feedback, setFeedback] = useState("")
 
    let refFeedback = firebase.firestore().collection("feedback")

    async function handleFeedbackSubmit(){
        refFeedback.doc().set({
            feedback:feedback,
            email: user && user.user && user.user.email?user.user.email : " ",
        })
    }

    return (
        <div style = {{marginLeft:"30em"}}>
                Tell us what you would like.
                <div>
                    <TextField rows={10}  sx={{width: '45ch' }} multiline onChange = {(e) => setFeedback(e.target.value)}/>
                    
                </div>
                <div style = {{marginTop:"1vh"}}/>
                <button onClick = {() => handleFeedbackSubmit()}>Send feedback!</button>
            </div>
    )
}