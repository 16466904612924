import {useState} from 'react'
import {useContext} from "react"
import { useLocation, Link } from 'react-router-dom'

import TextField from '@mui/material/TextField';
import Popup from 'reactjs-popup';

import firebase from "../../../firebase"
import { AuthContext } from "../../authentication/AuthContext"

import "./SuggestSkill.css"

export default function SuggestSkill() {

    let refRoadmapSuggestions = firebase.firestore().collection("elements")
    const increment = firebase.firestore.FieldValue.increment(1);
    const user = useContext(AuthContext);

    const location = useLocation()
    
    const [skillTitle, setSkillTitle] = useState("")
    const [actualLink1, setActualLink1] = useState("")
    const [actualLink2, setActualLink2] = useState("")
    const [actualLink3, setActualLink3] = useState("")
    const [link1Title, setLink1Title] = useState("")
    const [link2Title, setLink2Title] = useState("")
    const [link3Title, setLink3Title] = useState("")
    const [suggestedBy, setSuggestedBy] = useState(user.userFirebase? user.userFireBase.email : "")

    const handleChangeSuggestedBy = e =>{
        setSuggestedBy(e.target.value)
    }

    const handleChangeSkillTitle = e =>{
        setSkillTitle(e.target.value)
    }

    const handleChangeLink1Title = e =>{
        setLink1Title(e.target.value)
    }

    const handleChangeLink2Title = e =>{
        setLink2Title(e.target.value)
    }

    const handleChangeLink3Title = e =>{
        setLink3Title(e.target.value)
    }

    const handleChangeActualLink1 = e =>{
        setActualLink1(e.target.value)
    }

    const handleChangeActualLink2 = e =>{
        setActualLink2(e.target.value)
    }
    const handleChangeActualLink3 = e =>{
        setActualLink3(e.target.value)
    }

    async function suggestResource(){

        let skill = location.state.roadmap[0].id

        refRoadmapSuggestions.doc(skill).collection("skillSuggestions").doc(skillTitle).get().then((doc) => {
            if (doc.exists) {
                refRoadmapSuggestions.doc(skill).update({timesSuggested: increment})
            } else {
                refRoadmapSuggestions.doc(location.state.roadmap[0].id).collection("skillSuggestions").doc(skillTitle).set({
                    title: skillTitle,
                    link1Title: link1Title,
                    actualLink1: actualLink1,
                    link2Title: link2Title,
                    actualLink2: actualLink2,
                    link3Title: link3Title,
                    actualLink3: actualLink3,
                    suggestedBy: suggestedBy,
                    timesSuggested: 1,
                }); 
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    return (
        <div className = "SuggestLinkInfo__Container">
            <h1>Suggest a new skill for roadmap: {location.state.roadmap[0].data.title}</h1>
            <p> Thank you for sharing your thoughts and telling us what could upgrade the roadmap.</p>
            <div className = "TitleInput__Container">
                <TextField
                    value = {skillTitle}
                    onChange = {handleChangeSkillTitle}
                    label="Skill name"
                    defaultValue=""
                    helperText="*Required"
                />
            </div>
            <div className = "DescriptionInput__Container">
                <TextField
                    value = {link1Title}
                    onChange = {handleChangeLink1Title}
                    label="Link 1 name"
                    defaultValue=""
                    helperText="*Very helpful"
                />
                <TextField 
                    value = {actualLink1}
                    onChange = {handleChangeActualLink1}
                    label="Website Link 1"
                    defaultValue=""
                    helperText="*Very helpful"
                />
            </div>
            <div className = "DescriptionInput__Container">
            <TextField
                    value = {link2Title}
                    onChange = {handleChangeLink2Title}
                    label="Link 2 name"
                    defaultValue=""
                    helperText="*Not Required"
                />
                <TextField 
                    value = {actualLink2}
                    onChange = {handleChangeActualLink2}
                    label="Website Link 2"
                    defaultValue=""
                    helperText="*Not required"
                />
            </div>
            <div className = "DescriptionInput__Container">
            <TextField
                    value = {link3Title}
                    onChange = {handleChangeLink3Title}
                    label="Link 3 name"
                    defaultValue=""
                    helperText="*Not Required"
                />
                <TextField 
                    value = {actualLink2}
                    onChange = {handleChangeActualLink3}
                    label="Website Link 3"
                    defaultValue=""
                    helperText="*Not required"
                />
            </div>
            {!user.userFireBase?
            <div className = "DescriptionInput__Container">
                <TextField 
                    value = {suggestedBy}
                    onChange = {handleChangeSuggestedBy}
                    label="Email Adress"
                    defaultValue=""
                    helperText="*Not required"
                />
            </div> 
            : ""}
            {skillTitle !== ""? 
                <Link onClick = {() => suggestResource()} 
                    to={{pathname: "/"}}>
                    <button className = "ConfirmSuggestion__Button">This would help!</button>
                </Link> :
                <Popup trigger={<button className = "ConfirmSuggestion__Button">This would help!</button>} position="right center">
                    <p>Please add a skill name.</p>
                </Popup>
            }

        </div>
    )
}