// import sizeof from 'firestore-size'

import {useState, useEffect, useContext} from "react";
import { useLocation } from 'react-router-dom'

import SideBar from "./components/SideBar/SideBar"
import Roadmap from "./components/Roadmap/Roadmap"
import firebase from "./firebase"
import { AuthContext } from "./components/authentication/AuthContext"

import TextField from '@mui/material/TextField';

import "./App.css"

export default function App() {  
    
    const location = useLocation()
    const user = useContext(AuthContext);
    const increment = firebase.firestore.FieldValue.increment(1);

    let refElements = firebase.firestore().collection("elements")
    let refUsers = firebase.firestore().collection("users")

    const [elements, setElements] = useState<firebase.firestore.DocumentData>([]);
    const [arrows, setArrows] = useState<firebase.firestore.DocumentData>([]);
    const [change, setChange] = useState(false)
    const [root, setRoot] = useState(location.pathname.replace("/", "")? location.pathname.toLowerCase().replace("/", ""): "home")
    const [owner, setOwner] = useState("")
    const [roadmap, setRoadmap] = useState<firebase.firestore.DocumentData>([]);
    const [selectedArrowIDX, setSelectedArrowIDX] = useState(-1)
    const [startArrow, setStartArrow] = useState("")
    

    if (user.userFireBase == null){
        user.user = null
        user.user = {}
        user.user.skillsCompleted = {[root]: []}
    }

    async function updateElement(element, attribute, changeAttribute, idx){
        
        //
        // element: element;
        // attribute: attribute which is about to be changed;
        // changeAttribute: value of the attribute to change to;
        // idx: index of the element
        //

        if (attribute === "externalLinks"){
            const to_do = [...element.data.externalLinks, changeAttribute[0]]
            elements[idx].data.externalLinks = to_do
            elements[idx].data.lastUpdated = new Date()
        }
        else if (attribute === "pos"){
            elements[idx].data.xpos = changeAttribute[0]
            elements[idx].data.ypos = changeAttribute[1]
            elements[idx].data.lastUpdated = new Date()
        }
        else {
            elements[idx].data[attribute] = changeAttribute
            elements[idx].data.lastUpdated = new Date()
        }
        await refElements.doc(element.data.root).update({lastUpdated: new Date(),})
        await refElements.doc(element.data.root).collection("allChildren").doc("allChildren").set({allChildren: elements})

        setChange(!change)
    }

    async function deleteLink(idxLink, idxElement){
        let current = elements[idxElement].data.externalLinks
        current.splice(idxLink, 1);
        elements[idxElement].data.externalLinks = current
        await refElements.doc(root).collection("allChildren").doc("allChildren").set({allChildren: elements})
        setChange(!change)
    }

    async function addIntersection(root) {
        let ref = refElements.doc()
        elements.push({data: {
            internalLinks: [],
            externalLinks: [],
            owned: user.userFireBase.email,
            description: "",
            root: root,
            title: "<Edit name>",
            xpos: 500,
            ypos: 300,
            roadmapTitle: roadmap[0].data.title,
            linkPosition: 1,
            mainRoad: true,
            views: 0,
        },
        id: ref.id
        })
        await refElements.doc(root).collection("allChildren").doc("allChildren").set({allChildren: elements});
        setChange(!change)
    }

    async function deleteElement(elementIDX){
        elements.splice(elementIDX, 1)
        await refElements.doc(root).collection("allChildren").doc("allChildren").set({allChildren: elements});
        getElements(root);
    }
    
    useEffect(() => {
        getElements(root)
        getArrows(root)
        getRoadmap(root)
        if (user.userFireBase == null){
            user.user = null
            user.user = {}
            user.user.skillsCompleted = {[root]: []}
        }
    }, [root, change])

    useEffect(() => { 
        if (location.state){
            setRoot(location.state.root)
        }
    }, [location]);

    async function getArrows(root) {
        let q : Array<firebase.firestore.DocumentData> = []
        refElements.doc(root).collection("arrows").get()
        .then((doc) => {
            doc.forEach((d) =>{ q.push({id: d.id, data: d.data()}); 
        }) 
        setArrows(q[0].data.allArrows) 
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    async function getElements(root) {
        console.log("Loaded Elements")
        let q : Array<firebase.firestore.DocumentData> = []
        refElements.doc(root).collection("allChildren").get()
        .then((doc) => {
            doc.forEach((d) =>{ q.push({id: d.id, data: d.data()}); 
        }) 
        setElements(q[0].data.allChildren)
        refElements.doc(root).update({views: increment})
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }

    async function getRoadmap(root) {
        let q : Array<firebase.firestore.DocumentData> = []
        await refElements.doc(root).get()
        .then((doc) => { q.push({id: doc.id, data: doc.data()}) 
        })
        setRoadmap(q)
        if (q[0].data){
            setOwner(q[0].data.owned)
        }
    }
    
    async function updateSkillStatus(updatedSkills){
        await refUsers.doc(user.userFireBase.email).update({skillsCompleted: updatedSkills})
    }

    async function updateArrow(){
        arrows[selectedArrowIDX].mainArrow = arrows[selectedArrowIDX].mainArrow? false: true
        await refElements.doc(root).collection("arrows").doc("allArrows").set({
            allArrows:arrows
        });
        setChange(!change)
    }

    async function addArrow(elementId) {
        if (startArrow === "")
        {
            return
        }
        if (startArrow === elementId){
            return
        }
        arrows.push({start: startArrow,
            end: elementId})
        await refElements.doc(root).collection("arrows").doc("allArrows").set({
            allArrows:arrows
        });
        setChange(!change)
    }

    async function deleteArrow(){
        arrows.splice(selectedArrowIDX, 1);
        refElements.doc(root).collection("arrows").doc("allArrows").set({
            allArrows:arrows
        })
        getElements(root);
        setChange(!change)
        setSelectedArrowIDX(-1)
    }

    return (
        <div className="Container__All__Components" >
            <SideBar selectedRoadmap = {root}/>
            <div className = "Container__Roadmap">
                <Roadmap elements = {elements} arrows = {arrows}
                    selectedArrowIDX = {selectedArrowIDX} startArrow = {startArrow}
                    roadmap = {roadmap}
                    
                    updateElement = {updateElement} 
                    addIntersection = {addIntersection} 
                    deleteElement = {deleteElement} 
                    updateSkillStatus = {updateSkillStatus}

                    deleteLink = {deleteLink}

                    addArrow = {addArrow}
                    deleteArrow = {deleteArrow}
                    setSelectedArrowIDX = {setSelectedArrowIDX}
                    setStartArrow = {setStartArrow}
                    updateArrow = {updateArrow}
                />
            </div>
        </div>
    );
}
