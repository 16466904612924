import {useState, useContext} from 'react'
import { useLocation, Link } from 'react-router-dom'

import TextField from '@mui/material/TextField';
import Popup from 'reactjs-popup';

import { AuthContext } from "../../authentication/AuthContext"
import firebase from "../../../firebase"

import "./SuggestLink.css"

export default function SuggestLink() {

    let refRoadmapSuggestions = firebase.firestore().collection("elements")
    const user = useContext(AuthContext);

    const location = useLocation()
    
    const [linkTitle, setLinkTitle] = useState("")
    const [actualLink, setActualLink] = useState("")
    const [suggestedBy, setSuggestedBy] = useState(user.userFireBase? user.userFireBase.email : "")

    const handleChangeLinkTitle = e =>{
        setLinkTitle(e.target.value)
    }

    const handleChangeActualLink = e =>{
        setActualLink(e.target.value)
    }


    const handleChangeSuggestedBy = e =>{
        setSuggestedBy(e.target.value)
    }

    async function suggestLink(){
        refRoadmapSuggestions.doc(location.state.element.data.root).collection("linkSuggestions").doc().set({
            title: linkTitle,
            actualLink: actualLink,
            suggestedBy: suggestedBy,
        }); 
    }
    
    return (
        <div className = "SuggestLinkInfo__Container">
            <h1>Suggest a new link for skill: "{location.state.element.data.title}"</h1>
            <h2>In roadmap: "{location.state.element.data.roadmapTitle}"</h2>
            <p> Thank you for sharing your thoughts and telling us what could help other people.</p>
            <div className = "TitleInput__Container">
                <TextField
                    value = {linkTitle}
                    onChange = {handleChangeLinkTitle}
                    label="Link name"
                    defaultValue=""
                    helperText="*Required"
                />
            </div>
            <div className = "DescriptionInput__Container">
                <TextField 
                    value = {actualLink}
                    onChange = {handleChangeActualLink}
                    label="Website Link"
                    defaultValue=""
                    helperText="*Required"
                />
            </div>
            {!user.userFireBase?
            <div className = "DescriptionInput__Container">
                <TextField 
                    value = {suggestedBy}
                    onChange = {handleChangeSuggestedBy}
                    label="Email Adress"
                    defaultValue=""
                    helperText="*Not required"
                />
            </div> 
            : ""}
            {actualLink !== "" && linkTitle !== ""? 
                <Link onClick = {() => suggestLink()} 
                    to={{pathname: "/" + location.state.element.data.root}}>
                    <button className = "ConfirmSuggestion__Button">This would help!</button>
                </Link>
                : 
                <Popup trigger={<button className = "ConfirmSuggestion__Button">This would help!</button>} position="right center">
                    <p>Please add a link name and a website link</p>
                </Popup>
            } 

        </div>
    )
}