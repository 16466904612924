import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

// // This is the TEST
// const firebaseConfig = {
//     apiKey: "AIzaSyA68EU5oOVd57Rr-oHkPlgtMjdJqQ5b-kw",
//     authDomain: "progressionroadmap.firebaseapp.com",
//     projectId: "progressionroadmap",
//     databaseURL: "https://progressionroadmap-default-rtdb.europe-west1.firebasedatabase.app",
//     storageBucket: "progressionroadmap.appspot.com",
//     messagingSenderId: "715819201637",
//     appId: "1:715819201637:web:86392f985268b60524c28c",
//     measurementId: "G-37TCPD3F2G"
//   };

const firebaseConfig = {
  apiKey: "AIzaSyBx4KaYbksIuYszzMpaeYEB5OEj3SL3sIE",
  authDomain: "polluxroadmap.firebaseapp.com",
  projectId: "polluxroadmap",
  storageBucket: "polluxroadmap.appspot.com",
  messagingSenderId: "271684540866",
  appId: "1:271684540866:web:794f0de248bc02497e9e5b",
  measurementId: "G-NRC0CK45KF"
};

firebase.initializeApp(firebaseConfig);

export default firebase;
