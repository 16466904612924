import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import {AuthProvider} from "./components/authentication/AuthProvider"
import Main from "./Main"

import './index.css';

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <Main/>
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
