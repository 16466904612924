import {useState, useEffect, useContext} from 'react'
import {Link} from 'react-router-dom';

import {useXarrow} from 'react-xarrows';
import Draggable from 'react-draggable';

import ExternalLink from "./Links/ExternalLink"
import Start from "./Start"
import { AuthContext } from "../authentication/AuthContext"

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditIcon from '@mui/icons-material/Edit';

import "./Element.css"

export default function DraggableBox({element, updateElement, deleteLink, startArrow, setStartArrow, addArrow, idx, deleteElement, updateSkillStatus}) {
    
    const updateXarrow = useXarrow();
    const user = useContext(AuthContext);
    
    let superUser = user && user.userFireBase && user.userFireBase.email === "polluxroadmaps@gmail.com"

    const [position, setPosition] = useState({x: element.data.xpos, y: element.data.ypos})
    const [firstRender, setFirstRender] = useState(true)
    const [newIntersectionName, setNewIntersectionName] = useState(element.data.title)
    const [editElement, setEditElement] = useState(false)
    const [newLink, setNewLink] = useState([{link: "Actual Link", name: "Name", type: "website", clicks: 0}])
    const [change, setChange] = useState(false)

    let hideSuggestion = element.data.hideSuggestion

    useEffect(() => { 
        if (!firstRender) {
          updateElement(element, "pos", [position.x, position.y], idx) 
        }
    }, [position]);

    function handleClickLinkPosition(){
        updateElement(element, "linkPosition", (element.data.linkPosition+1)%2, idx) 
    }

    function handleDrag(e, ui){
        updateXarrow()
        setFirstRender(false)
        if (position.x !== ui.x && position.y !== ui.y){
          setPosition({x: ui.x, y: ui.y})
        }
    }
    
    function handleClickSkill(skillId){

        if (!user.user){
            return
        }

        if (!user.user.skillsCompleted[element.data.root]){
            user.user.skillsCompleted[element.data.root] = []
        }

        let filtered = user.user.skillsCompleted[element.data.root].filter(skill => skill !== skillId)
        filtered.length === user.user.skillsCompleted[element.data.root].length ? user.user.skillsCompleted[element.data.root].push(skillId) 
                                                                    : user.user.skillsCompleted[element.data.root] = filtered

        if (user.user.email) {
            updateSkillStatus(user.user.skillsCompleted)
        }

        setChange(!change)
    }

    const handleChangeNewIntersectionName = e => {
        setNewIntersectionName(e.target.value)
    }

    function handleChangeNewLink(e, position){
        if (position === 1){
            setNewLink([{link: e.target.value, name: newLink[0].name, type: newLink[0].type, clicks: 0}])
        }
        if (position === 2){
            setNewLink([{link: newLink[0].link, name: e.target.value, type: newLink[0].type, clicks: 0}])
        }
        if (position === 3){
            setNewLink([{link: newLink[0].link, name: newLink[0].name, type: e.target.value, clicks: 0}])
        }
    }

    function handleAddExternalLink(element, attribute, change){
        updateElement(element, attribute, change, idx)
    }

    if(element.data.start){
        return (
            <Draggable onDrag={updateXarrow} onStop={handleDrag}
                position={{x:position.x, y:position.y}}
                bounds={{top:0, left: 0}}
                disabled = {true}
            >
                <div id={element.id} className = "ElementBox__Start">
                    <Start/>
                    {user && user.userFireBase && user.userFireBase.email === "polluxroadmaps@gmail.com" ? 
                    <div>
                        <button onClick = {() => setStartArrow(element.id)}>StartArrow</button>
                        <button onClick = {() => addArrow(element.id)}>EndArrow</button>
                    </div>
                    : "" }
                </div>
            </Draggable>
        )
    }
    else{
        return (
            <div className = "ElementBox__Container">
                <Draggable onDrag={updateXarrow} onStop={handleDrag}
                    position={{x:position.x, y:position.y}}
                    bounds={{top:0, left: 0}}
                    disabled = {!superUser}
                >
                    <div className = "ElementBox">
                        {element.data.linkPosition === 0?
                            <div className = "LinkHyperlinks">
                                {editElement?<ArrowDownwardIcon onClick = {() => handleClickLinkPosition()}/>:""}
                                <div>
                                    {!hideSuggestion?
                                        <Link className = "Suggest__Link" to = {{pathname: "/suggestlink", 
                                            state: {element: element}}} >
                                            + Suggest Link
                                        </Link>
                                    : ""}
                                    {element.data.externalLinks.map((link, idxLink) => 
                                    <ExternalLink link = {link} idxLink = {idxLink} idxElement = {idx}
                                                  deleteLink = {deleteLink}
                                                  element = {element}/>)}
                                </div>
                            </div>
                        :""}       
                        <div id={element.id} className = 
                            {element.data.mainRoad?
                                (user.user && user.user.skillsCompleted && user.user.skillsCompleted[element.data.root] && user.user.skillsCompleted[element.data.root].filter(e => element.id === e).length > 0 ?  
                                    "ElementBox__MainRoad__Completed" : "ElementBox__MainRoad"
                                )
                                : 
                                (user.user && user.user.skillsCompleted && user.user.skillsCompleted[element.data.root] && user.user.skillsCompleted[element.data.root].filter(e => element.id === e).length > 0 ?  
                                    "ElementBox__SideRoad__Completed" : "ElementBox__SideRoad"
                                )
                            } 
                            onClick = {() => handleClickSkill(element.id)}>
                            {element.data.title} 
                            {superUser ? <EditIcon onClick = {() => setEditElement(!editElement)}/> : ""}
                            {editElement ? 
                                <div>
                                    <button onClick = {() => setStartArrow(element.id)}>StartArrow</button>
                                    <button onClick = {() => addArrow(element.id)}>EndArrow</button>
                                    <input value = {newIntersectionName} onChange = {handleChangeNewIntersectionName}></input>
                                    <button onClick = {() => updateElement(element, "title", newIntersectionName, idx)}>Edit Name</button>
                                </div>
                            : "" }
                        </div>
                        {editElement ? 
                        <div>
                            <p></p>
                            <input value = {newLink[0].link} onChange = {(e) => handleChangeNewLink(e, 1)}></input>
                            <input value = {newLink[0].name} onChange = {(e) => handleChangeNewLink(e, 2)}></input>
                            <input value = {newLink[0].type} onChange = {(e) => handleChangeNewLink(e, 3)}></input>
                            <button onClick = {() => handleAddExternalLink(element, 'externalLinks', newLink)}>
                                Add Link
                            </button>
                            <button onClick = {() => updateElement(element, "mainRoad", !element.data.mainRoad, idx)}>mainRoad</button>
                            <button onClick = {() => updateElement(element, "hideSuggestion", !element.data.hideSuggestion, idx)}>hideSuggestion</button>
                            <button onClick = {() => deleteElement(idx)}>Delete</button>
                        </div>
                        : ""}
                        {element.data.linkPosition === 1?
                            <div className = "LinkHyperlinks">
                                {editElement? <ArrowUpwardIcon onClick = {() => handleClickLinkPosition()}/> : ""}
                                <div>
                                    {element.data.externalLinks.map((link, idxLink) => 
                                        <ExternalLink link = {link} idxElement = {idx} idxLink = {idxLink}
                                                    deleteLink = {deleteLink}
                                                    element = {element}/>
                                        )}
                                    {!hideSuggestion?
                                        <Link className = "Suggest__Link" to = {{pathname: "/suggestlink", 
                                            state: {element: element}}} >
                                            + Suggest Link
                                        </Link>
                                    : ""}
                                </div>
                            </div>
                        :""}           
                    </div>
                </Draggable>
            </div>
        )
    }
};
