import { Switch, Route } from 'react-router-dom';

import App from './App';
import NewRoadMap from "./components/pages/NewRoadmaps/NewRoadmap"
import SuggestRoadMap from "./components/pages/SuggestRoadmap/SuggestRoadmap"
import Search from "./components/pages/Search/Search"
import TopBar from "./components/TopBar/TopBar"
import SideBar from "./components/SideBar/SideBar"
import SuggestLink from "./components/pages/SuggestLink/SuggestLink"
import SuggestSkill from "./components/pages/SuggestSkill/SuggestSkill"
import Feedback from "./components/pages/Feedback/Feedback"

import "./Main.css"

const Main = () => {

  return (
    <>
    <TopBar />
    <SideBar selectedRoadmap = {""}/>
      <Switch> {/* The Switch decides which component to show based on the current URL.*/}
        <Route exact path='/newroadmap' component={NewRoadMap}/>
        <Route exact path='/feedback' component={Feedback}/>
        <Route exact path='/suggestroadmap' component={SuggestRoadMap}></Route>
        <Route exact path='/suggestlink' component={SuggestLink}></Route>
        <Route exact path='/suggestskill' component={SuggestSkill}/>
        <Route exact path='/search' component={Search}></Route>
        <Route exact path='/' component={Search}/>
        <Route path = "/:rootID" component = {App}/>
      </Switch>
    </>
  );
}

export default Main;