import {useContext, useRef} from "react";
import { AuthContext } from "./AuthContext"
import firebase from "../../firebase"

import "./Auth.css"

export default function AuthHandler() {  

  const user = useContext(AuthContext);

  const signOut = async () => {
    user.user = null
    user.userFireBase = null
    await firebase.auth().signOut().then(() => {
    }).catch((error) => {
      console.log(error)
    })
  };

  async function handleSocialClick(sns: any){
    var provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithPopup(provider)
  }
  
  return (
    <div className="Container__All__Components">
      <div className = "Container__Authentication">
        <div>
          {!user.userFireBase ?
            <div style = {{display: "flex", alignItems: "center"}}>
              <button className = "signIn__Button" onClick = {handleSocialClick}>Log in</button>
              <button className = "signUp__Button"onClick = {handleSocialClick}>Sign up</button>
              <div className = "WhiteSpace" style = {{width: "35em"}}/>
              <div style = {{color: "#223554"}}>
                Sign in to track your progress.
              </div>
            </div>
            :
            <div className = "signOut__Container">
              <img src={user.userFireBase.photoURL} style = {{borderRadius: "1.2cm", width: "3em", padding: "0.2em"}}/>
              <button className = "signOut__Button" onClick = {signOut}>Sign out</button>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
