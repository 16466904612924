import {useState, useContext} from 'react'
import TextField from '@mui/material/TextField';
import Button from "@mui/material/Button"
import { Link } from "react-router-dom";
import firebase from "../../../firebase"
import Popup from 'reactjs-popup';

import { AuthContext } from "../../authentication/AuthContext"

const NewRoadmap = () => {
    let refElements = firebase.firestore().collection("elements")
    let refUsers = firebase.firestore().collection("users")

    const user = useContext(AuthContext);

    const [newTitle, setNewTitle] = useState("")
    const [description, setDescription] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("None selected")

    const handleChangeTitle = e =>{
        setNewTitle(e.target.value)
    }

    const handleChangeDescription = e =>{
        setDescription(e.target.value)
    }

    function handleChangeCategory(e, idx){
        setSelectedCategory(categories[idx])
    }

    async function addRoadmap(){
        let newRoot = newTitle.split(' ').join('_').toLowerCase()
        refElements.doc(newRoot).set({
            title: newTitle,
            root: newRoot,
            owned: user.userFireBase.email,
            views: 1,
            likes: 1,
            contributors: [user.userFireBase.email],
            description: description,
            category: selectedCategory,
            lastUpdated: new Date(),
            hideInSearch: true,
        })
        let ref = refElements.doc()
        refElements.doc(newRoot).collection("allChildren").doc("allChildren").set({allChildren: [{data: {
            internalLinks: [],
            externalLinks: [],
            root: newRoot,
            title: newTitle,
            xpos: 525,
            ypos: 173,
            roadmapTitle:newTitle,
            start: true
        },
        id: "start"}]
    }); 
        refElements.doc(newRoot).collection("arrows").doc("allArrows").set({
            allArrows: []
        }); 
        let q : Array<firebase.firestore.DocumentData> = []
        await refUsers.where("email", "==", user.userFireBase.email).get()
        .then((doc) => {doc.forEach((d) =>{ q.push({id: d.id, data: d.data()})})})
    }
    
    const categories = ["General", "Health", "Finance", "Psychology", "Software"]
    let root = refElements.doc().id

    return (
        <div style = {{marginLeft: "22em"}}>
            <h1>new roadmap</h1>
            <p>Title: <TextField id="outlined-basic" value = {newTitle} onChange = {handleChangeTitle} label="Outlined" variant="outlined" /></p>
            <p>Description: <TextField value = {description} onChange = {handleChangeDescription}/></p>
            {categories.map((e, idx) => <span onClick = {() => handleChangeCategory(e,idx)}> {e}</span>)}
            <p> Selected Category: {selectedCategory}</p>
            {user.userFireBase && selectedCategory !== "None selected" && user.userFireBase.email === "polluxroadmaps@gmail.com"? 
                <Link onClick = {() => addRoadmap()} 
                    to={{pathname: "/", 
                    state: {root: root}}}>
                    <Button variant="contained">Confirm</Button> 
                </Link>: 
                <Popup trigger={<Button variant="contained">Confirm</Button>} position="right center">
                <p>Log in to admin create a Roadmap and/or select a category</p>
            </Popup>}
            <p>
                <Link to="/"> <Button>Cancel</Button> </Link>
            </p>
        </div>
    )
}

export default NewRoadmap