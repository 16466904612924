import { useState, useEffect} from "react";
import firebase from "../../../firebase"
import { Link } from 'react-router-dom'

import CardElement from "./CardElement"

import "./Search.css"

export default function Search() {

  const [elements, setElements] = useState<firebase.firestore.DocumentData>([]);
  const [elementsToShow, setElementsToShow] = useState<firebase.firestore.DocumentData>([]);
  const [selectedCategory, setSelectedCategory] = useState(0)

  let refElements = firebase.firestore().collection("elements")

  async function getElementData() {
      const snapshot = await refElements.get()
      let elements = snapshot.docs.map(doc => doc.data());
      setElements(elements)
      setElementsToShow(elements)
  }

  useEffect(() => {
    getElementData()
  }, [])

  const categories = ["All", "Software"] //"General", "Health", "Finance", "Psychology",

  function handleClickCategorySelection(idx) {
    let filteredElements
    idx === 0 ? filteredElements = elements : filteredElements = elements.filter(e => e.category === categories[idx])
    setElementsToShow(filteredElements)
    setSelectedCategory(idx)
  }

  return (
    <>
      <div className = "topBarCategory__Container">
        {categories.map((category, idx) =>
          <div className = {selectedCategory === idx? "category__selected":"category"} onClick = {() => handleClickCategorySelection(idx)}>
              {category}
          </div>
        )}
      </div>
      <div className = "suggestion__Roadmap">
        <Link to = "/suggestroadmap">
          <div className = "suggestion__Button">
            Suggest a new Roadmap
          </div>
        </Link>
      </div>
      <div className = "searchGrid__Container">
          {elementsToShow.map((e, idx) => 
            e.hideInSearch? "" :
            <Link to={"/"+e.root}>
              <div  className = "cardElement__Container">
                <CardElement key = {e.id} element = {e}></CardElement>
              </div>
            </Link>
          )}
      </div>
    </>
  )
}


