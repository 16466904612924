// import {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom';

// import { AuthContext } from "../authentication/AuthContext"
// import firebase from "./../../firebase"

import "./SideBar.css"

export default function SideBar({selectedRoadmap}) {

    // const user = useContext(AuthContext);
    // let refElements = firebase.firestore().collection("elements")

    // const [allRoadmaps, setAllRoadmaps] = useState<firebase.firestore.DocumentData>([]);

    // async function getAllRoadmaps() {
    //     const snapshot = await refElements.get()
    //     let roadmaps = snapshot.docs.map(doc => doc.data());
    //     setAllRoadmaps(roadmaps)
    // }

    // useEffect(() =>{
    //     getAllRoadmaps()
    // }, [])

    let showRoadmaps = ["python", "algorithms_&_datastructures", "data_science"]
    let titleRoadmaps = ["Python", "Algorithms & Datastructures", "Data science"]

    return (
        <div className = "Container__SideBar">
            <div>
                <span>
                    CurseOfTheMaster@gmail.com
                </span>
                <div className = "Cotma__Title">
                    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.8962 13.2054C13.8962 14.1009 13.1703 14.8268 12.2749 14.8268C11.3795 14.8268 10.6536 14.1009 10.6536 13.2054C10.6536 12.31 11.3795 11.5841 12.2749 11.5841C13.1703 11.5841 13.8962 12.31 13.8962 13.2054Z" fill="#D5494C"/>
                        <path d="M17.9495 14.8268C18.845 14.8268 19.5709 14.1009 19.5709 13.2054C19.5709 12.31 18.845 11.5841 17.9495 11.5841C17.0541 11.5841 16.3282 12.31 16.3282 13.2054C16.3282 14.1009 17.0541 14.8268 17.9495 14.8268Z" fill="#D5494C"/>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0869 0.842865C7.54908 0.842865 1.38781 6.85652 1.20504 14.3921L0.875 28H29.2481L28.9699 14.4439C28.8148 6.88753 22.6449 0.842865 15.0869 0.842865ZM9.59891 10.0042C7.83089 10.0042 6.39763 11.4374 6.39763 13.2054C6.39763 14.9735 7.83089 16.4067 9.59891 16.4067H20.6255C22.3936 16.4067 23.8268 14.9735 23.8268 13.2054C23.8268 11.4374 22.3936 10.0042 20.6255 10.0042H9.59891Z" fill="#D5494C"/>
                    </svg>
                    <Link to = {{pathname: "/"}}>
                        <div className = "Cotma" style={{marginLeft:"0.5em"}}>Cotma</div>
                    </Link>
                </div>
            </div>
            <div>
                <div className = "Roadmaps__Title" style={{marginBottom:"1em", marginLeft:"1.3em"}}>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.37312 12.2964V4.48395C3.37312 2.5852 4.91187 1.04645 6.81062 1.04645C8.70937 1.04645 10.2481 2.5852 10.2481 4.48395V16.3589C10.2481 18.2577 11.7869 19.7964 13.6856 19.7964C15.5844 19.7964 17.1231 18.2577 17.1231 16.3589V8.54645" stroke="#223554" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
                        <path d="M17.1231 6.04645C18.5038 6.04645 19.6231 4.92716 19.6231 3.54645C19.6231 2.16574 18.5038 1.04645 17.1231 1.04645C15.7424 1.04645 14.6231 2.16574 14.6231 3.54645C14.6231 4.92716 15.7424 6.04645 17.1231 6.04645Z" stroke="#223554" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
                        <path d="M3.37312 19.7964C4.75384 19.7964 5.87312 18.6772 5.87312 17.2964C5.87312 15.9157 4.75384 14.7964 3.37312 14.7964C1.99241 14.7964 0.873123 15.9157 0.873123 17.2964C0.873123 18.6772 1.99241 19.7964 3.37312 19.7964Z" stroke="#223554" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square"/>
                    </svg>
                    <div style={{marginLeft:"0.5em"}}>Roadmaps</div>
                </div>
                <div className = "RoadmapName__Container">
                    {showRoadmaps.map((e, idx) => 
                        <Link className = {selectedRoadmap === e ? "RoadmapNameAndLink__Selected" : "RoadmapNameAndLink"}
                            to = {{pathname: "/" + e, 
                            state: {root: e}}} >
                                {titleRoadmaps[idx]}
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
}
