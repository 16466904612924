import {useState, useContext, useEffect} from 'react'
import {Link} from 'react-router-dom';

import Xarrow, { Xwrapper } from 'react-xarrows';

import Element from "./Element"
import { AuthContext } from "../authentication/AuthContext"


import "./Roadmap.css"

export default function Roadmap({elements, arrows, selectedArrowIDX, startArrow,
                                 addIntersection, updateElement, deleteElement,
                                 roadmap, updateArrow, updateSkillStatus,
                                 addArrow, setSelectedArrowIDX, deleteArrow,
                                 setStartArrow, deleteLink}) 
  {

  ///// XARROW NEEDS THIS BECAUSE IT DOES NOT RENDER!!??
  const [show, S] = useState(true)
  ///// XARROW DOESNT RENDER ON THE FIRST RENDER??!!

  document.addEventListener('visibilitychange', function (event) {
    if (document.hidden) {
      setAnimation(false)
    } else {
      setAnimation(true);
    }
  });
  
  const user = useContext(AuthContext);
  let superUser = user && user.userFireBase && user.userFireBase.email === "polluxroadmaps@gmail.com"

  const [showHead, setShowHead] = useState(true)
  const [animation, setAnimation] = useState(true)

  let liness = arrows.map((arrow, idx) => ({start: arrow.start, end: arrow.end, 
                                     color: superUser && (idx == selectedArrowIDX) ? 'darkorange' : 
                                     arrow.mainArrow? '#0520334d': '#517B9A4D',
                                     dashness: { animation: animation? 0:0 },}))

  if (show){
    return (
      <Xwrapper>
        {superUser? 
          <div className = "DevTools">
            <button style={{ marginLeft: "22em"}} onClick = {() => setShowHead(!showHead)}>Toggle Arrow Head</button>
            <button onClick = {() => setAnimation(!animation)}> Animation</button>
            <button onClick = {() => addIntersection(elements[0].data.root)}  >Add Intersection</button>
            <button onClick = {() => {deleteArrow(selectedArrowIDX)}}>Delete Arrow</button> 
            <button onClick = {() => {updateArrow(selectedArrowIDX)}}>Update Arrow</button> 
          </div>
          :""
        }
        <div className = "Roadmap__Title" style={{ marginLeft: "12em"}}>
          {roadmap.length > 0 && roadmap[0].data? roadmap[0].data.title:""}
        </div>
        <Link to = {{pathname: "/feedback", 
                    state: {roadmap: roadmap}}} >
            <div className = "Suggest__Skill" style={{ marginLeft: "12em"}}>
                + Give Feedback
            </div>
        </Link>
        <Link to = {{pathname: "/suggestskill", 
                    state: {roadmap: roadmap}}} >
            <div className = "Suggest__Skill" style={{ marginLeft: "12em"}}>
                + Suggest Skill
            </div>
        </Link>
        <div className = "roadmap" id="canvas">
            {elements.map((e, idx) => (
              <Element key={e.id} element = {e} updateElement = {updateElement} deleteLink = {deleteLink} deleteElement = {deleteElement}
                       startArrow = {startArrow} setStartArrow = {setStartArrow} addArrow = {addArrow} idx = {idx} updateSkillStatus = {updateSkillStatus}/>
            ))}
        </div>
        <div>
          {liness.map((line, idx) => (
            <div onClick = {() => setSelectedArrowIDX(idx)}>
              <Xarrow {...line} showHead = {showHead? true: false}/>
            </div>
          ))}
        </div>
      </Xwrapper>
    );
  }
  return (
    <Xwrapper>
      {user && user.userFireBase && user.userFireBase.email === "polluxroadmaps@gmail.com" ? <button onClick = {() => addIntersection(elements[0].data.root)}  >Add Intersection</button>:""}
      <div className = "roadmap" id="canvas">
          {elements.map((e, idx) => (
            <Element key={e.id} element = {e} updateElement = {updateElement} deleteLink = {deleteLink} deleteElement = {deleteElement}
                     startArrow = {startArrow} setStartArrow = {setStartArrow} addArrow = {addArrow} idx = {idx} 
                     updateSkillStatus = {updateSkillStatus}/>
          ))}
      </div>
    </Xwrapper>
  );
};

